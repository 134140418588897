import { Component, OnInit, HostListener, ElementRef, ViewChild, EventEmitter } from "@angular/core";
import { AppBaseComponent, AppService, AvoService, ReftagService, ScriptService, WebCommunityService } from "@mypxplat/xplat/web/core";
import {
  UserService,
  HelperService,
  ProductService,
  environment,
  WorkspaceService,
  StorageService,
  StorageKeys,
  EventsService,
  OrderService,
  SupportService,
  WindowService,
  EventBusService,
  CheckoutService,
} from "@mypxplat/xplat/core";
import { takeUntil, take, filter } from "rxjs/operators";
import { Location } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { ApplicationUpdateComponent, GenericProductPurchase, SwitchLanguageComponent, UpgradeSoftwareStripeComponent } from "@mypxplat/xplat/web/features";
import { signOut } from "aws-amplify/auth";

@Component({
  selector: "myp-root",
  templateUrl: "./app.component.html",
})
export class AppComponent extends AppBaseComponent implements OnInit {
  @HostListener("window:beforeunload", ["$event"])
  doSomething($event) {
    this.workspaceService.uploadingFile$.pipe(take(1)).subscribe((result) => {
      if (result) $event.returnValue = "You are currently uploading a file, if you reload now it will be canceled.";
    });
  }

  sidebarClosed: boolean = false;
  myStyle: object = {};
  myParams: object = {};
  width: number = 100;
  height: number = 100;
  authNeeded: boolean = true;
  public env = environment;
  @ViewChild("appContainer") appContainer: ElementRef;
  public showHeader: boolean = false;

  constructor(
    public userService: UserService,
    public helperService: HelperService,
    public productService: ProductService,
    public eventsService: EventsService,
    public orderService: OrderService,
    public supportService: SupportService,
    public appService: AppService,
    public workspaceService: WorkspaceService,
    public communityService: WebCommunityService,
    public fbAuth: AngularFireAuth,
    public spin: NgxSpinnerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public avoService: AvoService,
    private _win: WindowService,
    private _location: Location,
    private _cookieService: CookieService,
    private _storageService: StorageService,
    private _translateService: TranslateService,
    private _scriptService: ScriptService,
    public eventBusService: EventBusService,
    public reftagService: ReftagService,
    public checkoutService: CheckoutService
  ) {
    super(userService);
    // this.appService.setTokensFromCookies();
    this.appService.app = "sphere";
    this.userService.appId = "sphere";
    let languageToUse = "en";
    if (this.user && this.user.language_code) {
      languageToUse = this.user.language_code;
    } else {
      const savedLanguage = this._storageService.getItem(StorageKeys.LANGUAGE);
      if (savedLanguage) {
        languageToUse = savedLanguage;
      }
    }
    if (!this.userService.availableLanguages[languageToUse]) languageToUse = "en";

    this._translateService.setDefaultLang(languageToUse);
    moment.locale(languageToUse);
    this._translateService.use(languageToUse);

    this.eventBusService.observe(this.eventBusService.types.showNewCodeAlert).subscribe((result) => {
      this.appService.showModal(ApplicationUpdateComponent, { size: "lg" });
    });
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showHeader = !this.router.url.includes("/checkout") && !this.router.url.includes("/onboarding"); // Hide header on checkout page
        this.setup();
      }
    });
  }

  setup() {
    const checkoutParamFlow = () => {
      this._win.setTimeout(() => {
        let checkoutUrlParam = this.activatedRoute.snapshot.queryParamMap.get("checkout");
        if (location.pathname == "/buy") {
          let checkoutProductType = "Perpetual";
          if (checkoutUrlParam) checkoutProductType = "Generic";
          this.appService.goToCheckout(
            `/checkout?checkoutProductType=${checkoutProductType}${checkoutUrlParam ? "&checkoutProductKey=" + checkoutUrlParam : ""}`,
            this.env.stripe.products[checkoutUrlParam] ? this.env.stripe.products[checkoutUrlParam].paeId : null
          );
        }
        if (checkoutUrlParam && this.env.stripe.products[checkoutUrlParam]) {
          this.appService.goToCheckout(`/checkout?checkoutProductType=Generic&checkoutProductKey=${checkoutUrlParam}`, this.env.stripe.products[checkoutUrlParam].paeId);
        }
      }, 1000);
    };
    if (!this.router.url.includes("/checkout")) {
      this.avoService.initAvoFunctions(environment.avo.apiKey, "Studio One+", "1.0.0");
      if (this.env.features.cookie_management) this._scriptService.loadScript(environment.usercentricsScriptId);
      if (this.env.production) this._scriptService.loadScript("googletagmanager");
      this._scriptService.loadScript("stripe");
      if (this.user) {
        this.communityService.getFirebaseProfile();
        this.avoService.avoInitted$.pipe(filter((result) => !!result)).subscribe((result) => {
          if (!this._storageService.checkTimestamp("logged_in_event_triggered")) {
            this.avoService.trackEvent().loggedIn({
              userId_: this.user.id,
              prsId: this.user.id,
              siteId: this.user.active_subscription ? "studio_one_plus" : "mypresonus",
            });
            this._storageService.setTimestamp("logged_in_event_triggered", 24);
          }
          if (this._storageService.getItem(StorageKeys.CHECKOUT_COMPLETED_DATA)) {
            this.avoService.trackEvent().orderCompleted(this._storageService.getItem(StorageKeys.CHECKOUT_COMPLETED_DATA));
            this._storageService.removeItem(StorageKeys.CHECKOUT_COMPLETED_DATA);
          }
        });
        checkoutParamFlow();
      } else {
        this.userService.user$
          .pipe(
            filter((user) => !!user),
            take(1)
          )
          .subscribe((result) => {
            this.communityService.getFirebaseProfile();
            checkoutParamFlow();
          });
      }
    }
    this.appService.showLangSwitcher$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.appService.showModal(SwitchLanguageComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    });
    this.appService.initTheme();

    if (this._cookieService.get("presonus-connect-session-destroy")) {
      this.productService.clearCache();
      this.eventsService.clearCache();
      this.orderService.clearCache();
      this.supportService.clearCache();
      this.communityService.clearCache();
      this.workspaceService.clearCache();
      this.userService.logout().subscribe((result) => {
        signOut()
          .then(() => {
            this.appService.deleteCookies();
            this.fbAuth.signOut();
            this.appService.redirectToConnect(true);
          })
          .catch(() => {
            this.appService.deleteCookies();
            this.fbAuth.signOut();
            this.appService.redirectToConnect(true);
          });
      });
    }

    this.helperService.backPressed$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this._location.back();
    });
  }

  ngAfterViewInit() {
    this.appService.appContainer = this.appContainer.nativeElement;
  }
}
